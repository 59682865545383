import React, { useState, useRef } from "react";

import { IoIosSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../api/api";
import { showToast } from "../../functions";
import "../../styles/searchBox.css";
const SearchBox = (props) => {
  const [trial, setTrial] = useState(false);
  const [error, setError] = useState("");

  const inputRef = useRef();

  const navigate = useNavigate();

  const fetchData = async () => {
    setTrial(true);
    if (inputRef.current.value.length < 3) {
      toast.error("Query must be greater than 2 letters");
      return;
    }
    const response = await api.get(`search/${inputRef.current.value}`);

    if (response && response.status == 200) {
      setTrial(false);

      localStorage.setItem("search", JSON.stringify(response.data));
      localStorage.setItem(
        "queryString",
        JSON.stringify(inputRef.current.value)
      );
      localStorage.removeItem("lowToHigh");
      if (window.location.pathname === "/journal") window.location.reload();
      navigate("/journal");
    } else {
      // setError(response.)
      setTrial(false);
      console.log("Error");
    }
  };
  return (
    <>
      <form
        className={
          props?.variant == "light"
            ? "searchBoxDiv lightSearchBox"
            : "searchBoxDiv"
        }
        onSubmit={(e) => {
          e.preventDefault();
          fetchData();
        }}
      >
        <span>
          <IoIosSearch />
        </span>
        <input
          type="text"
          placeholder="Keywords: ISSN, Journal Title"
          ref={inputRef}
        />
        <button className="searchBtn">Search</button>
      </form>
      {error}
    </>
  );
};

export default SearchBox;
