import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Journal from "../pages/Journal";
import Journals from "../pages/Journals";
import Main from "../pages/Main";
import _404 from "../pages/_404";
const RoutePath = () => {
  return (
    <Routes>
      <Route path="" exact element={<Home />} />
      <Route element={<Main />}>
        <Route path="/journal" element={<Journals />} />
        <Route path="/journal/:id" element={<Journal />} />
      </Route>
      <Route path="*" element={<_404 />} />
    </Routes>
  );
};

export default RoutePath;
