import React from "react";
import { Link } from "react-router-dom";
import "../../styles/header.css";
const Header = (props) => {
  const path = window.location.pathname;

  return (
    <section className="headerSection">
      <div className="headerTitle">{props?.title}</div>
      <div className="breadCrumbs">
        <Link to="/">Home</Link>
        {path.split("/")[1] && (
          <>
            &gt;
            <Link to="/journal">{path.split("/")[1]}</Link>
          </>
        )}
        {path.split("/")[2] && (
          <>
            &gt; <Link>Details</Link>
          </>
        )}
      </div>
    </section>
  );
};

export default Header;
