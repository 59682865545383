import React, { useEffect, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  IoChevronDown,
  IoCloseCircle,
  IoCheckmarkCircle,
} from "react-icons/io5";
import { useParams } from "react-router-dom";

import Header from "../components/header/Header";
import api from "../api/api";
import "../styles/accordion.css";
import Loader from "../components/Loader";

const Journal = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [impactFactor, setImpactFactor] = useState(null);
  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [params]);
  useEffect(() => {
    console.log(data);
  }, [data]);

  const fetchData = async () => {
    const response = await api.get(`get_journal/${params?.id}`);
    if (response.status === 200) {
      setData(response?.data);
      setError("");
      setLoading(false);
    } else {
      setError("Something went wrong");
      setLoading(false);
    }
  };

  const isDoaj = () => {
    let res = false;
    data?.forEach((obj) => {
      res = res || obj?.in_doaj;
    });
    return res;
  };
  const isEmbase = () => {
    let res = false;
    data?.forEach((obj) => {
      res = res || obj?.in_embase;
    });
    return res;
  };
  const isMedline = () => {
    let res = false;
    data?.forEach((obj) => {
      res = res || obj?.in_medline;
    });
    return res;
  };
  const isPmc = () => {
    let res = false;
    data?.forEach((obj) => {
      res = res || obj?.in_pmc;
    });
    return res;
  };
  const isScie = () => {
    let res = false;
    data?.forEach((obj) => {
      res = res || obj?.in_scie;
    });
    return res;
  };
  const isScopus = () => {
    let res = false;
    data?.forEach((obj) => {
      res = res || obj?.in_scopus;
    });
    return res;
  };

  const getImpactFactor = () => {
    let factor = null;
    let factor_latest = null;
    let h_index = null;
    let sjr = null;
    data?.forEach((obj) => {
      factor = factor || obj?.impact_factor;
      factor_latest = factor_latest || obj?.impact_factor_latest;
      h_index = h_index || obj?.h_index;
      sjr = sjr || obj?.sjr;
    });
    return [factor, factor_latest, h_index, sjr];
  };
  if (!params?.id) return <></>;

  return (
    <section className="marginSection">
      <Header
        title={
          (data && data[0]?.journal_title) || (data && data[0]?.source_title)
        }
      />

      {loading ? (
        <Loader />
      ) : (
        <>
          <section
            className="paddingSection individualJournalSection"
            style={{ minHeight: "1vh", padding: "0 6vw" }}
          >
            <div className="indexingWrapper" style={{ border: "none" }}></div>
            <div className="journalDetailsWrapper">
              <div className="journalIssn">
                ISSN/E-ISSN :{" "}
                {data &&
                  ((data[0]?.issn != "N/A" && data[0]?.issn) ||
                    data[0]?.e_issn)}
              </div>
{/* <div className="journalIssn">
                Coverage Years :{" "}
                {(data && data[0]?.coverage_years) || "Unavailable"}
              </div> */}
            </div>
          </section>
          <section className="paddingSection individualJournalSection">
            <div className="indexingWrapper">
              <div className="indexingHeading">Indexed In</div>
              <div className="indexingDetails">
                <div className="indexingRow">
                  <span className="indexQuery">PMC</span>
                  <span className="indexIcon">
                    {isPmc() ? (
                      <IoCheckmarkCircle color="#20C959" />
                    ) : (
                      <IoCloseCircle color="#A4A4A4" />
                    )}
                  </span>
                </div>

                <div className="indexingRow">
                  <span className="indexQuery">SCIE</span>
                  <span className="indexIcon">
                    {isScie() ? (
                      <IoCheckmarkCircle color="#20C959" />
                    ) : (
                      <IoCloseCircle color="#A4A4A4" />
                    )}
                  </span>
                </div>

                <div className="indexingRow">
                  <span className="indexQuery">DOAJ</span>
                  <span className="indexIcon">
                    {isDoaj() ? (
                      <IoCheckmarkCircle color="#20C959" />
                    ) : (
                      <IoCloseCircle color="#A4A4A4" />
                    )}
                  </span>
                </div>

                <div className="indexingRow">
                  <span className="indexQuery">Embase</span>
                  <span className="indexIcon">
                    {isEmbase() ? (
                      <IoCheckmarkCircle color="#20C959" />
                    ) : (
                      <IoCloseCircle color="#A4A4A4" />
                    )}
                  </span>
                </div>

                <div className="indexingRow">
                  <span className="indexQuery">Medline</span>
                  <span className="indexIcon">
                    {isMedline() ? (
                      <IoCheckmarkCircle color="#20C959" />
                    ) : (
                      <IoCloseCircle color="#A4A4A4" />
                    )}
                  </span>
                </div>

                <div className="indexingRow">
                  <span className="indexQuery">Scopus</span>
                  <span className="indexIcon">
                    {isScopus() ? (
                      <IoCheckmarkCircle color="#20C959" />
                    ) : (
                      <IoCloseCircle color="#A4A4A4" />
                    )}
                  </span>
                </div>
              </div>
            </div>

            {data && (
              <div className="journalDetailsWrapper">
                <div className="accordionWrapper">
                  {/* GENERAL DEATAILS */}

                  <Accordion className="accordion" defaultExpanded>
                    <AccordionSummary
                      expandIcon={<IoChevronDown />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordionSummary"
                    >
                      <span className="accordionTitle">General Details</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <span className="accordionRow">
                        <div className="accordionQuery detailedRes">
                          Impact Factor 2022
                        </div>
                        <div className="accordionRes">
                          <span className="detailedRes">
                            {(data && getImpactFactor()[0]) || "Unavailabe"}
                          </span>
                          {/* <sub>({data && getImpactFactor()[1]})</sub> */}
                        </div>
                      </span>
                      <span className="accordionRow">
                        <div className="accordionQuery detailedRes">
                          H-Index
                        </div>
                        <div className="accordionRes">
                          <span className="detailedRes">
                            {(data && getImpactFactor()[2]) || "Unavailabe"}
                          </span>
                        </div>
                      </span>
                      <span className="accordionRow">
                        <div className="accordionQuery detailedRes">
                          SJR Quartile
                        </div>
                        <div className="accordionRes">
                          <span className="detailedRes ">
                            {(data && getImpactFactor()[3]) || "Unavailabe"}
                          </span>
                        </div>
                      </span>
                    </AccordionDetails>
                  </Accordion>

                  {/* PMC DETAILS */}
                  {/* {isPmc() && (
                  <Accordion className="accordion" defaultExpanded>
                    <AccordionSummary
                      expandIcon={<IoChevronDown />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordionSummary"
                    >
                      <span className="accordionTitle">PMC Details</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <span className="accordionRow">
                        <div className="accordionQuery">Earliest Volume</div>
                        <div className="accordionRes">
                          <span>
                            {(data && data[0]?.earliest_volume) ||
                              "Unavailable"}
                          </span>
                        </div>
                      </span>
                      <span className="accordionRow">
                        <div className="accordionQuery">Lastest Issue</div>
                        <div className="accordionRes">
                          <span>
                            {(data && data[0]?.latest_issue) || "Unavailable"}
                          </span>
                        </div>
                      </span>
                      <span className="accordionRow">
                        <div className="accordionQuery">Participation</div>
                        <div className="accordionRes">
                          <span>
                            {(data && data[0]?.participation) || "Unavailable"}
                          </span>
                        </div>
                      </span>
                    </AccordionDetails>
                  </Accordion>
                )} */}

                  {/* SCIE DETAILS */}
                  {/* {isScie() && (
                  <Accordion className="accordion" defaultExpanded>
                    <AccordionSummary
                      expandIcon={<IoChevronDown />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordionSummary"
                    >
                      <span className="accordionTitle">SCIE Details</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <span className="accordionRow">
                        <div className="accordionQuery">
                          Impact Factor 2022 (IF 2021)
                        </div>
                        <div className="accordionRes">
                          <span>{data && data[0]?.impact_factor_latest}</span>
                          <sub>({data && data[0]?.impact_factor})</sub>
                        </div>
                      </span>
                      <span className="accordionRow">
                        <div className="accordionQuery">H-Index</div>
                        <div className="accordionRes">
                          <span>{data && data[0]?.h_index}</span>
                        </div>
                      </span>
                      <span className="accordionRow">
                        <div className="accordionQuery">SJR Quartiles</div>
                        <div className="accordionRes">
                          <span>{data && data[0]?.sjr}</span>
                        </div>
                      </span>
                    </AccordionDetails>
                  </Accordion>
                )} */}

                  {/* DOAJ DETAILS */}
                  {/* {isDoaj() && (
                  <Accordion className="accordion" defaultExpanded>
                    <AccordionSummary
                      expandIcon={<IoChevronDown />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordionSummary"
                    >
                      <span className="accordionTitle">DOAJ Details</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <span className="accordionRow">
                        <div className="accordionQuery">
                          Article Processing Charge
                        </div>
                        <div className="accordionRes">
                          <span>{(data && data[0]?.apc) || "Unavailable"}</span>
                        </div>
                      </span>
                      <span className="accordionRow">
                        <div className="accordionQuery">APC Amount</div>
                        <div className="accordionRes">
                          <span>
                            {(data && data[0]?.apc_amount) || "Unavailable"}
                          </span>
                        </div>
                      </span>
                      <span className="accordionRow">
                        <div className="accordionQuery">Added On</div>
                        <div className="accordionRes">
                          <span>
                            {(data && data[0]?.added_on) || "Unavailable"}
                          </span>
                        </div>
                      </span>
                    </AccordionDetails>
                  </Accordion>
                )} */}

                  {/* EMBASE DETAILS */}
                  {/* {isEmbase() && (
                  <Accordion className="accordion" defaultExpanded>
                    <AccordionSummary
                      expandIcon={<IoChevronDown />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordionSummary"
                    >
                      <span className="accordionTitle">Embase Details</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <span className="accordionRow">
                        <div className="accordionQuery">
                          Impact Factor 2022 (IF 2021)
                        </div>
                        <div className="accordionRes">
                          <span>{data && data[0]?.impact_factor_latest}</span>
                          <sub>({data && data[0]?.impact_factor})</sub>
                        </div>
                      </span>
                      <span className="accordionRow">
                        <div className="accordionQuery">H-Index</div>
                        <div className="accordionRes">
                          <span>{data && data[0]?.h_index}</span>
                        </div>
                      </span>
                      <span className="accordionRow">
                        <div className="accordionQuery">SJR Quartiles</div>
                        <div className="accordionRes">
                          <span>{data && data[0]?.sjr}</span>
                        </div>
                      </span>
                    </AccordionDetails>
                  </Accordion>
                )} */}

                  {/* MEDLINE DETAILS */}
                  {/* {isMedline() && (
                  <Accordion className="accordion" defaultExpanded>
                    <AccordionSummary
                      expandIcon={<IoChevronDown />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordionSummary"
                    >
                      <span className="accordionTitle">Medline Details</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <span className="accordionRow">
                        <div className="accordionQuery">
                          Impact Factor 2022 (IF 2021)
                        </div>
                        <div className="accordionRes">
                          <span>{data && data[0]?.impact_factor_latest}</span>
                          <sub>({data && data[0]?.impact_factor})</sub>
                        </div>
                      </span>
                      <span className="accordionRow">
                        <div className="accordionQuery">H-Index</div>
                        <div className="accordionRes">
                          <span>{data && data[0]?.h_index}</span>
                        </div>
                      </span>
                      <span className="accordionRow">
                        <div className="accordionQuery">SJR Quartiles</div>
                        <div className="accordionRes">
                          <span>{data && data[0]?.sjr}</span>
                        </div>
                      </span>
                    </AccordionDetails>
                  </Accordion>
                )} */}

                  {/* SCOPUS DETAILS */}
                  {/* {isScopus() && (
                  <Accordion className="accordion" defaultExpanded>
                    <AccordionSummary
                      expandIcon={<IoChevronDown />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordionSummary"
                    >
                      <span className="accordionTitle">Scopus Details</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <span className="accordionRow">
                        <div className="accordionQuery">Active/Inactive</div>
                        <div className="accordionRes">
                          <span>
                            {(data && data[0]?.active) || "Unavailable"}
                          </span>
                        </div>
                      </span>
                      <span className="accordionRow">
                        <div className="accordionQuery">Coverage Years</div>
                        <div className="accordionRes">
                          <span>
                            {(data && data[0]?.coverage_years) || "Unavailable"}
                          </span>
                        </div>
                      </span>
                    </AccordionDetails>
                  </Accordion>
                )} */}
                </div>
              </div>
            )}
          </section>
        </>
      )}
    </section>
  );
};

export default Journal;
