import { createContext, useState, useEffect } from "react";
const ThemeContext = createContext({});

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(true);
  const [disclaimerOpen, setDisclaimerOpen] = useState(true);

  const toggleTheme = () => {
    setTheme(!theme);
  };
  const toggleDisclaimerOpen = () => {
    setDisclaimerOpen(!disclaimerOpen);
  };
  useEffect(() => {
    if (theme) {
      setBackground("#fff");
      setText("#151515");
      setBoxShadow("rgba(99, 99, 99, 0.2) 0px 2px 8px 0px");
    } else if (!theme) {
      setBackground("#151515");
      setText("#fff");
      setBoxShadow("rgba(255, 255, 255, 0.2) 0px 2px 8px 0px");
    }
  }, [theme]);
  const setBackground = (color) => {
    document.documentElement.style.setProperty("--background-color", color);
  };
  const setText = (color) => {
    document.documentElement.style.setProperty("--text", color);
  };

  const setBoxShadow = (color) => {
    document.documentElement.style.setProperty("--box-shadow", color);
  };
  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
        disclaimerOpen,
        toggleDisclaimerOpen,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
