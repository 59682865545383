import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { IoMdSunny, IoIosMoon } from "react-icons/io";

import logo from "../../assets/logo.png";
import logoWhiteWash from "../../assets/logo-whitewash.png";
import ThemeContext from "../../context/ThemeProvider";

import "../../styles/nav.css";
const Nav = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <section className="navBar">
      <Link to="/">
        <img
          src={theme ? logo : logoWhiteWash}
          alt="journal-checker-logo"
          className="navLogo"
        />
      </Link>
      <div className="togglerButton" onClick={toggleTheme}>
        {theme ? <IoMdSunny /> : <IoIosMoon />}
      </div>
    </section>
  );
};

export default Nav;
