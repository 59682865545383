import React from "react";
import "../../styles/cards.css";
import { IoIosCalendar } from "react-icons/io";
import { Link } from "react-router-dom";
const Card = (props) => {
  return (
    <Link to={"/journal/" + props?.card?.id} className="card">
      <div className="cardTop">
        <span className="issn">ISSN: &nbsp;{props?.card?.issn}</span>
        <Link to={"/journal/" + props?.card?.id} className="cardTitle">
          {props?.index}.{" "}
          {props?.card?.journal_title ||
            props?.card?.source_title ||
            "Untitled"}
        </Link>
      </div>
      {/* <div className="cardDates">
        <span>
          <IoIosCalendar />
        </span>
        <span>{props?.card?.coverage_years || "N/A"}</span>
      </div> */}
      <div className="cardBottom">
        <Link to={"/journal/" + props?.card?.id}>View More &gt;&gt;</Link>
      </div>
    </Link>
  );
};

export default Card;
