import React from "react";
import { Link } from "react-router-dom";
import empty from "../assets/empty.svg";
const _404 = () => {
  return (
    <div className="marginSection">
      <div className="emptyDiv">
        <img src={empty} alt="404 image" />
        <h3>Oops! There seems to be an error.</h3>
        <Link to="/" style={{ marginTop: 30, textDecoration: "underline" }}>
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default _404;
