export function GetSortOrder(prop, type) {
    if (type)
        return function (a, b) {
            if (parseFloat(a[prop]) > parseFloat(b[prop])) {
                return 1;
            } else if (parseFloat(a[prop]) < parseFloat(b[prop])) {
                return -1;
            }
            return 0;
        }

    return function (a, b) {
        if (parseFloat(a[prop]) < parseFloat(b[prop])) {
            return 1;
        } else if (parseFloat(a[prop]) > parseFloat(b[prop])) {
            return -1;
        }
        return 0;
    }

}


export function sort_by_key(array, key, lth) {
    if (lth)
        return array.sort(function (a, b) {
            var x = parseFloat(a[key]); var y = parseFloat(b[key]);
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    else
        return array.sort(function (a, b) {
            var x = parseFloat(a[key]); var y = parseFloat(b[key]);
            return ((x > y) ? -1 : ((x < y) ? 1 : 0));
        });
}

