import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Nav from "../components/Nav/Nav";

const Main = () => {
  return (
    <div>
      <Outlet />
      {/* <Footer /> */}
    </div>
  );
};

export default Main;
