import React, { useContext } from "react";
import "../styles/home.css";
import logo from "../assets/logo.png";
import logoWhiteWash from "../assets/logo-whitewash.png";
import qtanealogo from "../assets/whitelogo.webp";
import qtanealogoDark from "../assets/darkLogo.webp";

import ThemeContext from "../context/ThemeProvider";
import SearchBox from "../components/searchBox/SearchBox";
import { Link } from "react-router-dom";
const Home = () => {
  const { theme, disclaimerOpen, toggleDisclaimerOpen } =
    useContext(ThemeContext);

  return (
    <section className="homeSection ">
      <img
        src={theme ? logo : logoWhiteWash}
        alt="logo-home"
        className="homeLogo"
      />
      <div className="searchBoxWrapper">
        <SearchBox />
      </div>
      <div className="searchBoxMessage">
        This search engine searches through relevant database(s) and checks
        whether the journal is indexed according to the latest NMC guidelines
        SCIE (Web of Science), Pubmed Central, Scopus, Embase, DOAJ and Medline.
      </div>

      <div className="supportedByWrapper homeSupportedByWrapper">
        <span>Supported by:</span>
        <Link
          to="https://qtanea.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={theme ? qtanealogoDark : qtanealogo} alt="" />
        </Link>
      </div>

      <div
        className={
          disclaimerOpen ? "open disclaimerSection" : "disclaimerSection"
        }
      >
        <div>
          <span>Disclaimer</span>
          <p>
            We endeavour to constantly keep our database updated. Journal
            indexing remains in constant flux, with frequent additions and
            deletions being the norm. Hence, we ask you to review the indexing
            of the journal once through the official journal website, before
            making any submissions. This website is not liable in case of any
            mishaps.
          </p>
        </div>
        <span className="disclaimerCloseBtn" onClick={toggleDisclaimerOpen}>
          &times;
        </span>
      </div>
    </section>
  );
};

export default Home;
