import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Card from "../components/cards/Card";
import Header from "../components/header/Header";
import SearchBox from "../components/searchBox/SearchBox";
import empty from "../assets/empty.svg";
import Footer from "../components/Footer/Footer";
import "../styles/journal.css";
import { sort_by_key } from "../functions";

const Journals = () => {
  const [results, setResults] = useState(() => {
    if (localStorage.getItem("search"))
      return JSON.parse(localStorage.getItem("search"));
    else return [];
  });

  const [queryString, setQueryString] = useState(() => {
    if (localStorage.getItem("queryString"))
      return JSON.parse(localStorage.getItem("queryString"));
    else return "";
  });

  const [filter, setFilter] = useState(() => {
    if (localStorage.getItem("lowToHigh"))
      return JSON.parse(localStorage.getItem("lowToHigh"));
    return false;
  });

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   setQueryString(JSON.parse(localStorage.getItem("queryString")));
  //   setResults(JSON.parse(localStorage.getItem("search")));
  // }, [localStorage.getItem("search")]);

  useEffect(() => {
    console.log(filter ? "Low to High" : "High to Low", filter);
    let newRes = sort_by_key(results, "impact_factor", filter);
    localStorage.setItem("search", JSON.stringify(newRes));
  }, [filter]);

  return (
    <section className="marginSection">
      <Header title="Journals" />
      <div className="journalSearchSection">
        <SearchBox />
      </div>

      <section className="paddingSection">
        <div className="journalSearchWrapperTop">
          <div className="resultCounter">
            Showing Results for{" "}
            <strong>
              {queryString} ({results.length})
            </strong>
          </div>
          <div className="filterDiv">
            <span>Sort By</span>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 150 }}
              size="small"
            >
              <InputLabel id="impactFactor-label">Impact Factor</InputLabel>
              <Select
                labelId="impactFactor-label"
                id="impactFactor-select"
                value={filter}
                label="filter"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={true}>Low to High</MenuItem>
                <MenuItem value={false}>High to Low</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="cardWrapper">
          {results && results.length > 0 ? (
            sort_by_key(results, "impact_factor", filter)?.map(
              (result, idx) => (
                <div className="cardBox">
                  <Card card={result} index={idx + 1} />
                </div>
              )
            )
          ) : (
            <div className="emptyDiv">
              <img src={empty} alt="" />
              Nothing to show
            </div>
          )}
        </div>
      </section>
      {/*<Footer />*/}
    </section>
  );
};

export default Journals;
